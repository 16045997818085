import React, { useEffect, useState } from 'react';
import { Alert, TextField, Typography } from '@mui/material';
import QuestionTypography from './QuestionTypography';
import { useRel } from '../../system';
// If you have additional MUI components you want to use, import them here

// This is the template for your TypeAComponent
// It expects to receive an "item" prop that it will use to display content

function decodeHtmlEntities(html) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = html;
  return textArea.value;
}

const ItemTextArea = ({ item, setItemValue, oldValues, onValidityChange, onItemsChange }) => {

  // You can access all properties of "item" and use them in your component
  // For example, if "item" has a "name" property, you can display it like so

  const [value, setValue] = useState(''); // State to hold the value of the textarea
  const [showHelp, setShowHelp] = useState(false); // Assuming each item might have a defaultValue
  const rel = useRel();
  // ====================================
  // important
  // flush state if item changes
  // ====================================
  useEffect(() => {
    const foundItem = oldValues.find(element => element.item === item.id);
    const oldValue = foundItem ? foundItem.value : '';
    const currentIsValid = validateValue(oldValue);
    setValue(oldValue||'');         // set default value for this component, assign no Value to Moodle form (setItemValue)
    setItemValue(item.id, oldValue||''); // Set the item value in the App component's state
    onValidityChange(item.id, currentIsValid);
    onItemsChange(item.id, ()=>{const v=oldValue; return (() => { setShowHelp(!validateValue(v))})() });
}, [item]); 

  const validateValue = (currentValue) => {
    // Your validation condition here, for example, value should not be the default 50
    if(currentValue===undefined) return false
    if(currentValue===null) return false
    if(typeof currentValue === "string" && currentValue.length<0) return false
    return true;
  };

  const handleChange = (event) => {
    setValue(event.target.value);              // Update the state of this component when the input changes
    setItemValue(item.id, event.target.value); // Set the item value in the App component's state

    // Check validity and update state
    const currentIsValid = validateValue(event.target.value);
    
    // Call the function passed via props to update the parent component
    onValidityChange(item.id, currentIsValid);
    onItemsChange(item.id, ()=>{const v=value; return (() => { setShowHelp(!validateValue(v))})() } );
    setShowHelp(!validateValue(value))

  };

  // ====================================
  // important
  // flush state if item changes
  // ====================================
  useEffect(() => {
    const foundItem = oldValues.find(element => element.item === item.id);
    const oldValue = foundItem ? foundItem.value : '';
    const currentIsValid = validateValue(oldValue);
    setValue(oldValue||'');         // set default value for this Slider component, assign no Value to Moodle form (setItemValue)
    onValidityChange(item.id, currentIsValid);
    onItemsChange(item.id, ()=>{const v=oldValue; return (() => { setShowHelp(!validateValue(v))})() });
  }, [item]); 



  return (
    <div>
      {/* Question text */}

      <QuestionTypography text={item.name} showHelp={showHelp} helpText={"Ole hyvä ja vasta avoimeen kysymykseen:"} sx={{ p:rel(1)}}/>

      <TextField
        label="kirjoita tähän..."
        multiline

        sx={{ 
          width: "100%",
          // Set the background color for the entire OutlinedInput component
          backgroundColor: 'white',
          '& .MuiOutlinedInput-root': {
            // Override the styles for the input field
            '& input': {
              backgroundColor: 'white',
            },
            '& fieldset': {
              borderColor: 'grey',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'blue',
            },
          },
        }} 

        rows={4} // You can adjust the number of rows
        value={value}
        onChange={handleChange}
        variant="outlined"
        fullWidth // Makes the TextField take the full width of its container
      />
    </div>
  );
};

// Make sure to export your component so it can be used in other parts of your app
export default ItemTextArea;




