import React, { useState, useEffect, } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import FooterButtons from './FooterButtons';
import FooterInfo from './FooterInfo'; 
import showMessage from '../lib/showMessage';
import ShowItems from './ShowItems';
import useGetFeedbackQuestionsForPage from '../lib/useGetFeedbackQuestionsForPage';
import CollapsibleCard from './CollapsibleCard';
import FullScreenLoader from './FullScreenLoader';
import { useRel } from '../system';

function ErrorCard({ errorMessage, }) {
    return (
    <Box 
        sx={{
            width: "100%",
            display: 'flex',         // Added for flexbox layout
            justifyContent: 'center', // Centers flex items on the main axis (horizontally)
            alignItems: 'center',     // Centers flex items on the cross axis (vertically)
            //height: '100vh'           // Optional: Full viewport height if you want to center it vertically as well
        }}    
        >   
      <Card sx={{ maxWidth: 345, backgroundColor: 'error.main', color: 'white', m: 2 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Tietokantavirhe
          </Typography>
          <Typography variant="body2">
            {errorMessage}
          </Typography>
        </CardContent>
      </Card>
    </Box>
    );
}

const  CompleteFeedback = () => {

    const {userCode, answerCode, moodleUserToken:token, fbid:feedbackId, courseId} = useParams();
    const rel = useRel();

    const [isLoading, setIsLoading] = useState(true);
    const [submitOK, setSubmitOK] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState(''); 
    const [itemValues, setItemValues] = useState([]); // values of items on current page, reset in FooterButtons, after submit
    const [pageNumber, setPageNumber] = useState(0); 

    const [logVarsFullList, setLogVarsFullList] = useState({}); 
    const [itemsFullList, setItemsFullList] = useState([]); 

    // for square bracket notation destructuring takes place by position!
    const [items, hasNextPage, hasPrevPage, oldValues, error, getFeedbackQuestionsForPage, pageCount ] = useGetFeedbackQuestionsForPage({userCode, answerCode, token, feedbackId, setIsLoading, setItemsFullList}); 

    // =======================================================
    // =======================================================

    // State to track the validity of each item
    const [itemsValidity, setItemsValidity] = useState({});  // array holding id => validity info
    const [itemsHelpers, setItemsHelpers] = useState({});    // function calls to show helper messages upon next page button click say
    
    const areAllItemsValid = () => {
        // Check if there are no items in the itemsValidity object
        if (Object.keys(itemsValidity).length === 0) {
          return false;
        }
        // If there are items, check if all items are valid
        return Object.values(itemsValidity).every(isValid => isValid === true);
      };

    // Function to update validity state
    const onValidityChange = (id, isValid) => {
        setItemsValidity(prevValidity => ({
        ...prevValidity,
        [id]: isValid,
        }));
    };

    // =======================================================
    // =======================================================

    const onItemsChange = (id, helperFunction) => {
        setItemsHelpers(prevValidity => ({
        ...prevValidity,
        [id]: helperFunction,
        }));
    };

    useEffect(() => {
        setSubmitOK(areAllItemsValid())
    }, [itemsValidity]); 

    useEffect(() => {
        getFeedbackQuestionsForPage(pageNumber);
      }, [token, answerCode, pageNumber ]); 

    const handleSetItemValue = (itemId, value) => {
      // function automagically appends the old state to prevValues 
      // (the old value of the state variable)
      // and return new stehe where we have [itemId]: value 
      setItemValues(prevValues => ({
        ...prevValues,
        [itemId]: value
      }));
    };
  
    // Add the CircularProgress component conditionally
    let content;
    if (token) {
            content = <ShowItems 
                                token={token} 
                                items={items} 
                                itemValues={itemValues} 
                                setItemValue={handleSetItemValue} 
                                oldValues={oldValues}  
                                onValidityChange={onValidityChange}
                                onItemsChange={onItemsChange} // this will collect items helpers
                    />;
        }

    // Update logging vars in feedback      
    useEffect(() => {
      updateLogVars(logVarsFullList);
      updateLogSystem();

    }, [answerCode, pageNumber, logVarsFullList, itemsFullList]); 


    // ================================================
    // Updating logging items programmatically
    // ================================================
    const updateLogVars = (data) => {

      const now = new Date();
      const unixTimestamp = Math.floor(now.getTime() / 1000);
      const finlandDateTime = now.toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' });
      data.nameReceive = data.name;
      delete data.name;
      // data.name 
      // data.nameGive
      // data.roleGive
      // data.roleReceive
      // data.roleGiveId
      // data.roleReceiveId
      // data.userGiveId
      // data.userReceiveId
      delete data.status;
      data = {
        _feedback_courseid: courseId,
        _name2name: data.nameGive+"  ====  "+data.nameReceive+" ( " +finlandDateTime+ " )",
        ...data,
        userCode, 
        answerCode, 
        moodleUserToken:token, 
        feedbackId,
        unixTimestamp,
      }
      //console.log("logging data: ", data);
      const logVarsItem = itemsFullList.find(obj => obj.typ === 'alogvars');
      const data_jsonString = JSON.stringify(data);
      logVarsItem && handleSetItemValue(logVarsItem.id, data_jsonString) ;
    }

    const updateLogSystem = () => {
      if (!itemsFullList) return;
      const logSystemItem = itemsFullList.find(obj => obj.typ === 'alogsystem');
      if (!logSystemItem) return;
      //console.log("Logsystem item:",logSystemItem )
      let oldValue = itemValues[logSystemItem.id]?itemValues[logSystemItem.id]:'';

      const now = new Date();
      const unixTimestamp = Math.floor(now.getTime() / 1000);
      const finlandDateTime = now.toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' });

      if(!oldValue){
        // set initial values 
        oldValue=oldValue+unixTimestamp+",";
        oldValue=oldValue+finlandDateTime+",";
      }
      let commaIndex=-1;
      let beforeComma=-1;
      let timeStart=-1;
      try{
        // Find the index of the first comma
        commaIndex = oldValue.indexOf(',');
        // Extract the substring up to the comma
        beforeComma = oldValue.substring(0, commaIndex);
        // Extract only the digits
        timeStart = beforeComma.match(/\d+/)[0];
      } catch {
        // if issues set to empty
        handleSetItemValue(logSystemItem.id, "")  
        return;
      }
      const newLogEntry = oldValue+"on page "+pageNumber+","+(unixTimestamp-timeStart)+" seconds from beginning,";
      handleSetItemValue(logSystemItem.id, newLogEntry);
    }

    if(isLoading) return <FullScreenLoader open={isLoading}/>;
    //  backgroundColor:"red"
    
    return( 
       <>
            <Container style={{margin:"0px", padding:rel(1)}} >  
                <CollapsibleCard feedbackId={feedbackId} answerCode={answerCode} userCode={userCode} setLogVarsFullList={setLogVarsFullList} sx={{maxWidth: "calc(1200px - "+rel(2)+")", }}/>
                <Grid container alignItems="center" justifyContent="center" sx={{ width: '100%', pt:rel(0), }} > 
                    <Grid item  xs={12} style={{ textAlign: 'center' }} display={isError? 'block' : 'none'}>
                        <ErrorCard errorMessage={"tiedot puuttuvat"}/>
                    </Grid>
                    <Grid item  xs={12} display={!isError ? 'block' : 'none'} sx={{ textAlign: 'center'}} >
                       {content}
                    </Grid>
                    <Grid item  xs={12} >          
                        <FooterButtons 
                            hasNextPage={hasNextPage} 
                            hasPrevPage={hasPrevPage} 
                            itemValues={itemValues} 
                            setItemValues={setItemValues}
                            items={items}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            submitOK={submitOK}
                            itemsHelpers={itemsHelpers}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            
                            userCode={userCode}
                            answerCode = {answerCode}
                            token={token} 
                            feedbackId={feedbackId}
                            pageCount={pageCount}
                          
                            />
                        <FooterInfo message={message} />
                    </Grid>

                </Grid>
               
            </Container>      
        </>
    )

}

export default CompleteFeedback