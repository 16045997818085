import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Grid, IconButton, LinearProgress } from '@mui/material';
import { Container } from '@mui/system';
import { styled } from '@mui/system';


import FontSelector from '../theme/FontSelector';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { isMobile } from '../system';
import InfoAccordion from './InfoAccordion';

const FixedContainer = styled(Container)({
    position: 'fixed',
    top: 0,
    left: '50%', // Center the container
    transform: 'translateX(-50%)', // Adjust for the container's own width
    zIndex: 1000,
    backgroundColor: 'white', // Adjust as needed
    pt: 0, // Padding top 3 (using spacing from the theme)
    mt: 0, // Padding top 3 (using spacing from the theme)
});


function HeaderButtons({ userCode, }) {

  const [showAccordion, setShowAccordion] = useState(false);
  const handleHelp = () => {
    setShowAccordion(!showAccordion); // Show the accordion on button click
  };
  const handleClose = () => {
    setShowAccordion(false); // Hide the accordion
  };
 
  useEffect(()=>{
    
  },[])
  

  return (<>
      <FixedContainer sx={{pt:0, mt:1, backgroundColor: "rgba(255, 255, 255, 0)" }}>
      <Grid container  spacing={0}>
      <Grid item xs="auto" sx={{ 
                  opacity: isMobile() ? 1 : 0.5, 
                  '&:hover': { 
                      opacity: 1 
                  } 
              }}>
          <FontSelector />
      </Grid>
      <Grid 
            item 
            xs   
            sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' ,
                    minHeight:20,
                  }}
          >
        </Grid>
        <Grid item xs="auto" style={{ textAlign: 'right' }}>
        <IconButton 
            style={{ margin: 0, padding: 0 }}
            size="large"
            onClick={() => handleHelp()}
            aria-label="small font">
            <ContactSupportOutlinedIcon fontSize="inherit" /> 
        </IconButton>
        </Grid>
      </Grid>
      </FixedContainer> 
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: 999, 
          position: 'fixed', // Fixed position to cover the entire viewport
          top: 0,           // Start from the top edge of the viewport
          left: 0,          // Start from the left edge of the viewport
          width: '100vw',   // Cover the full viewport width
          height: '100vh',  // Cover the full viewport height
          backgroundColor: '#fff', // White background
          opacity: 1, // No transparency
        }}
        open={showAccordion}
      >
      {showAccordion && <InfoAccordion userCode={userCode} handleClose={handleClose}/>}
      </Backdrop>
    </>
  );
}

export default HeaderButtons;
