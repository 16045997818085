import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRel } from '../system';


export default function ProgressBarWithLabel({progress}) {
  const rel = useRel();
  return (
            <Box sx={{ display: 'flex', alignItems: 'center',  flexGrow: 1, }} style={{width: '100%'}}>
              <Box sx={{ mr: rel(0.5), flexGrow: 1, minWidth: '20px' }} >
                  {/* Use 'value' prop instead of 'progress' */}
                  <LinearProgress variant="determinate" value={progress}  />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                  <Typography variant="subtitle1" color="text.secondary" sx={{fontSize:rel(0.8), m:0, p:0}}>{`${Math.round(progress)} %`}</Typography>
              </Box>
            </Box>
  );
}
