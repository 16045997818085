import React, { useEffect, useState } from 'react';
import { Button, Grid, LinearProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import processFeedbackPage from '../lib/processFeedbackPage';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import FooterInfo from './FooterInfo';
import setCompletedTmp from '../lib/setCompletedTmp';
import setCompleted from '../lib/setCompleted';
import ProgressBarWithLabel from './ProgressBarWithLabel';
import { useRel } from '../system';

function FooterButtons({ 
            hasNextPage, 
            hasPrevPage, 
            itemValues, 
            setItemValues, 
            items, 
            pageNumber, 
            setPageNumber, 
            submitOK, 
            itemsHelpers,
            isLoading,
            setIsLoading,

            userCode,
            answerCode,
            token, 
            feedbackId, 
            pageCount,

          }) {

  const [buttonText, setButtonText] = useState('seuraava')
  const [buttonColor, setButtonColor] = useState('primary')

  const rel = useRel();

  const navigate = useNavigate();
  const [progress, setProgress] = useState(0) // for progress bar
  useEffect(()=>{
    setProgress((pageNumber+1)*100.0/pageCount);
  },[pageNumber])
  

  useEffect(() => {
    setButtonText(hasNextPage || token === "" || token == null || pageNumber===0? 'seuraava' : 'tallenna')
    setButtonColor('primary')
  }, [hasNextPage,token]); 

  const handleSubmit = async (goprevious) => {
    try {
        const responses = Object.keys(itemValues).map((itemId, index) => {
            // Find the item first
            const item = items.find(item => Number(item.id) === Number(itemId));
            // Check if the item exists and has a 'typ' property
            const itemType = item && item.typ ? `${item.typ}_${itemId}` : `UnknownType_${itemId}`;
            // Return the response object

            // using the reduce method in JavaScript to 
            // flatten an array of objects into a single object.
            //
            // const responses = [
            //   { a: 1, b: 2 },
            //   { b: 3, c: 4 },
            //   { d: 5 }
            // ];
            // is transformend into this
            // { a: 1, b: 3, c: 4, d: 5 }
            //
            return {
              [`responses[${index}][name]`]: itemType,
              [`responses[${index}][value]`]: itemValues[itemId],
            };
      });

      // using the reduce method in JavaScript to flatten an array of objects 
      // into a single object. Let's break down what's happening in this code:
      const flattenedResponses = responses.reduce((acc, current) => {
        return { ...acc, ...current };
      }, {});

      const result = await processFeedbackPage(feedbackId, pageNumber, flattenedResponses, token, goprevious, answerCode);
      console.log("processFeedbackPage returns: ",result);
      if(result.completed) {
        setIsLoading(false) // this removes the loading screen on last page ()
        // is OK like this, we do not have to wait for setCompleted() to finnish,
        // in the worst case scenario setCompleted() fails,
        // but no permananent harm has been done, user just needs to fill in the form again
        setCompleted({moodleUserToken:token, feedbackid:feedbackId, answerCode:answerCode});
        navigate('/ty/'+userCode);
      } else {
        setCompletedTmp({moodleUserToken:token, feedbackid:feedbackId, answerCode:answerCode});
      }

      setPageNumber(result.jumpto);
      // setItemValues({}); // clear these values from state NO NEED, DESTROYS JUMPING BETWEEEN PAGES!!!
      // <FooterInfo message={"Virhe tietoliikenneyhteydessä"} />
      } catch (error) {
          console.error("An error occurred during form submission:", error);
          // Handle the error as needed
      } finally {
      
      }
  };

  const handlePrevClick = () => {
    if(!submitOK){
      Object.values(itemsHelpers).forEach(help=>help());
      return
    }
    setIsLoading(true)
    handleSubmit(1);
  };

  const handleNextClick = () => {
    if(!submitOK){
      Object.values(itemsHelpers).forEach(help=>help());
      return
    }
    setIsLoading(true)
    handleSubmit(0);
  };

  return (<>
      <Container sx={{ m: 0, p: 0, pt: rel(1.5), flexGrow: 1, width: "100%",  }}>
      <Grid container >
        <Grid item >
          <Button
            variant="contained"
            disabled={!hasPrevPage || token === "" || token === null || isLoading }
            onClick={handlePrevClick}
            sx={{
              fontSize: {xs: rel(0.8), sm: rel(0.8)} ,
              fontWeight: 700,
              p: {xs:rel(0.3), sm:rel(0.5)},
              pt: {xs:rel(0.1), sm:rel(0.3)},
              pb: {xs:rel(0.1), sm:rel(0.3)}, 
          }}
          ><span>edellinen</span>
          </Button>

        </Grid>
        <Grid 
            item 
            xs   
            sx={{ 
                    flexGrow: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' ,
                    minHeight:20,
                    pl: {xs:rel(0.3), md:rel(1.)},
                    pr: {xs:rel(0.3), md:rel(1.)},
                  }}
          >

            <ProgressBarWithLabel progress={progress} />

        </Grid>
        <Grid item 
              xs="auto" 
              sx={{ justifySelf: 'end',}}
              style={{ padding:"0px"}}
            >
          <Button
              endIcon={(hasNextPage && token!=null)||(token === "" || token === null || isLoading)||pageNumber===0?null:<SendIcon style={{ fontSize: rel(0.8) }} />}
              color={submitOK?"success":buttonColor}
              variant="contained"
              disabled={token === "" || token === null || isLoading }
              onClick={handleNextClick}
              sx={{
                fontSize: {xs: rel(0.8), sm: rel(0.8)} ,
                fontWeight: 700,
                p: {xs:rel(0.3), sm:rel(0.5)},
                pt: {xs:rel(0.1), sm:rel(0.3)},
                pb: {xs:rel(0.1), sm:rel(0.3)}, 
              }}
          ><span>{buttonText}</span>
          </Button>
        </Grid>
      </Grid>
      </Container> 
    </>
  );
}

export default FooterButtons;
