import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// https://stackoverflow.com/questions/65060150/not-getting-service-worker-with-create-react-app

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Choose one of the following two options:

// Option 1: Register the service worker with default configuration
// serviceWorkerRegistration.register();

// Option 2: Register with custom configuration (if needed)
// serviceWorkerRegistration.register({
//   onUpdate: registration => {
//     // Custom callback for when an update is found
//   },
//   onSuccess: registration => {
//     // Custom callback for when registration is successful
//   },
// });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
