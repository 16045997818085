import { useTheme } from "@emotion/react";

// Safe function to get data from localStorage
export const getLocalStorageItem = (key, defaultValue) => {
    try {
      const item = localStorage.getItem(key);
      return item ? item : defaultValue;
    } catch (error) {
      console.error("Error accessing localStorage:", error);
      return defaultValue;
    }
  };
  
  // Safe function to set data in localStorage
  export const setLocalStorageItem = (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error("Error saving to localStorage:", error);
    }
  };

  export const useRel = () => {
    const theme = useTheme(); 
    return (rem) => theme.typography.fontSize*rem+'px'
  };

  export  function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Check for iOS and Android mobile browsers
    return /iPhone|iPad|iPod|Android/i.test(userAgent);
  }
  