import React, {  useState } from 'react';

import IconButton from '@mui/material/IconButton';
import SmallOutlinedIcon from '@mui/icons-material/TextDecreaseOutlined';
import SmallFilledIcon from '@mui/icons-material/TextDecrease';

import MediumOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import MediumFilledIcon from '@mui/icons-material/FontDownload';

import LargeOutlinedIcon  from '@mui/icons-material/TextIncreaseOutlined';
import LargeFilledIcon  from '@mui/icons-material/TextIncrease';

import { useFontSize } from './FontSizeProvider';
import { Grid } from '@mui/material';

import { FONT_SIZES } from '../config_firebase';
const scale=1.5;
const filledIcons = [
  <SmallFilledIcon  sx={{ fontSize: FONT_SIZES[0]*scale+"px" }} />,
  <MediumFilledIcon sx={{ fontSize: FONT_SIZES[1]*scale+"px" }} />,
  <LargeFilledIcon sx={{ fontSize: FONT_SIZES[2]*scale+"px" }} />
];

const outlinedIcons = [
  <SmallOutlinedIcon sx={{ fontSize: FONT_SIZES[0]*scale+"px" }} />,
  <MediumOutlinedIcon sx={{ fontSize: FONT_SIZES[1]*scale+"px" }} />,
  <LargeOutlinedIcon sx={{ fontSize: FONT_SIZES[2]*scale+"px" }} />
];

const FontSelector = () => {
  const { fontSize, setFontSize } = useFontSize();
  const handleFontSizeChange = (sizeIndex) => { 
    const fontSizeValue = FONT_SIZES[sizeIndex];
    setFontSize(fontSizeValue); // Update font size
  };

  const isActive = (sizeIndex) => {
    return fontSize == FONT_SIZES[sizeIndex];
  };


  return (
    <Grid container alignItems="center" spacing={1}>
      {FONT_SIZES.map((size, index) => (
        <Grid item key={index}>
          <IconButton
            size={"large"}
            style={{ margin: 0, padding: 0 }}
            color={isActive(index) ? 'primary' : 'default'}
            onClick={() => handleFontSizeChange(index)}
            aria-label={`${size}px font`}>
            {/* Conditional rendering based on active state */}
            {isActive(index) ? 
              filledIcons[index] : 
              outlinedIcons [index]}
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};
  
export default FontSelector;

