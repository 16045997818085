import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NothingToSeeHere from './components/NothingToSeeHere';
import MainMenu from './components/MainMenu';
import CompleteFeedback from './components/CompleteFeedback';
import { ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';
import AppTheme from './theme/App-theme';
import ThankYou from './components/ThankYou';
import { FontSizeProvider } from './theme/FontSizeProvider';
import ThemeComponent from './theme/App-theme';
import Statistics from './components/Statistics';

// Lazy load the NothingToSeeHere component
//const NothingToSeeHereLazy = lazy(() => import('./components/NothingToSeeHere'));
//const MainMenuLazy         = lazy(() => import('./components/MainMenu')); 
//const ShowFeedback         = lazy(() => import('./components/MainMenu')); 

// =============================================== 
// Issues to fix:
//  - testing on a same machine, in multiple roles, only one answer possible
//  - textfield html entities
//  - loading indicator during first run, blank page for a while
//  - statistics, how to handle test person, name starts with *, ????

function App() {

  return (
    <>
     <FontSizeProvider>
      <ThemeComponent>
        <Container style={{margin:"auto", padding:"0px"}} >
          <Router>
              <Routes>

                <Route path="/mm/:userCode" element={<MainMenu />} />
                <Route path="/mm/:userCode/:moodleUserToken" element={<MainMenu />} />
                <Route path="/fb/:userCode/:answerCode/:moodleUserToken/:fbid/:courseId" element={<CompleteFeedback />} />
                <Route path="/ty/:userCode" element={<ThankYou />} />
                <Route path="/seuranta/:folderId" element={<Statistics />} />
                <Route path="*" element={<NothingToSeeHere />} />

              </Routes>
          </Router>
        </Container>
      </ThemeComponent>
    </FontSizeProvider>      
    </>
  );
}

export default App;