import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import QuestionTypography from './QuestionTypography';
import { Typography } from '@mui/material';
// If you have additional MUI components you want to use, import them here

// This is the template for your TypeAComponent
// It expects to receive an "item" prop that it will use to display content
const ItemEndScreen = ({ item }) => {
  // You can access all properties of "item" and use them in your component
  // For example, if "item" has a "name" property, you can display it like so
  return (
    <>
      <QuestionTypography text={"Tämä on palautekyselyn viimeinen sivu, \n tallenna tiedot klikkaamalla Tallenna-painiketta."} sx={{textAlign: "center", pt:2 }}/>
      <Typography gutterBottom align="center" sx={{ alignSelf: 'flex-end', lineHeight:1.2, pb:2}} > { /* Align this item to the bottom of the flex container */ }
            Halutessasi voit palata vastaukseesi avaamalla vastauslinkin<br/> tällä samalla laitteella ja samalla selainohjelmalla.
      </Typography>
    </>
  );
};

// Make sure to export your component so it can be used in other parts of your app
export default ItemEndScreen;
