import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FEEDBACKENDPOINT_GET_MANAGER_DETAILS } from '../config_firebase';
import { useEffect, useState } from 'react';
import { useRel } from '../system';


export default function CollapsibleCard({ feedbackId, answerCode, userCode, title="Esko Esihenkilö", subtitle="palautteen saajan rooli: esihenkilö", sx, setLogVarsFullList}) {
  
  const [expanded, setExpanded] = useState(false);
  const rel = useRel();
  const [name, setName] = useState('');
  const [infoText, setInfoText] = useState('');
  const [roleGive, setRoleGive] = useState('');
  const [roleReceive, setRoleReceive] = useState('');
  const [roleGiveId, setRoleGiveId] = useState(-1);
  const [roleReceiveId, setRoleReceiveId] = useState(-1);
  const [userGiveId, setUserGiveId] = useState(-1);
  const [userReceiveId, setUserReceiveId] = useState(-1);



  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(()=>{
    if(!answerCode || !feedbackId) return;
    const params = {
        feedbackId, 
        answerCode,
    };

    const queryString = new URLSearchParams(params).toString();
    const url = `${FEEDBACKENDPOINT_GET_MANAGER_DETAILS}?${queryString}`;
    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setName(data.name); 
            setRoleGive(data.roleGive);
            setRoleReceive(data.roleReceive);
            setRoleGiveId(data.roleGiveId);
            setRoleReceiveId(data.roleReceiveId);
            setUserGiveId(data.userGiveId);
            setUserReceiveId(data.userReceiveId);

            setLogVarsFullList(data);

            // check for self-reflection
            if(data.userGiveId==data.userReceiveId){
                setInfoText("Tämä on palautteensaajan itsearviointi, pohdi tässä omaa toimintaasi." );
            } else {
                //setInfoText("palautteensaajan rooli: "+data.roleReceive); // +" >> "+roleReceive+" "+data.userGiveId+ " "+data.userReceiveId
                setInfoText(""); // +" >> "+roleReceive+" "+data.userGiveId+ " "+data.userReceiveId
            }
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });

  },[answerCode]);
  
  
  return (

    <Card sx={{ 
            position: 'fixed',
            top: rel(0.1),
            left: '50%', // Move the card to the middle
            transform: 'translateX(-50%)', // Shift the card back by half its width
            zIndex: 10,
            width:"100%",
            ...sx,
        }}
    >

        <CardActions disableSpacing sx={{ padding: 0 }}>
            <Typography variant="h5" sx={{fontSize: rel(1.2) }}>
                palautteensaaja: {name}
            </Typography>
            <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{
                marginLeft: 'auto', // Pushes the icon to the far right
                padding: rel(0.5), 
            }}
            >
            <ExpandMoreIcon />
            </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent sx={{ 
                        padding: rel(0.),  
                        '&:last-child': {
                            pb: rel(0.4), // Removes padding from the bottom of the card content
                        },
                    }}
                >
            {/* Your content here  */}
                <Typography  
                        sx={{ 
                                fontSize: rel(1.2),
                                paddingLeft: rel(2), 
                            }}
                >
                     {infoText}
                </Typography>
            </CardContent>
        </Collapse>
        </Card>
  );
}
