import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fetchMoodleAnswerCodes from '../lib/fetchMoodleAnswerCodes';
import moodleUserInit from '../lib/moodleUserInit';
import showMessage from '../lib/showMessage';
import { Button,  Stack, Box, Typography, } from '@mui/material';
import FullScreenLoader from './FullScreenLoader';
import launchFeedback from '../lib/launchFeedback';
import { FEEDBACKENDPOINT_IS_ENROLLED } from '../config_firebase';
import QuestionTypography from './items/QuestionTypography';
import CompleteFeedbackButton from './CompleteFeedbackButton';
import HeaderButtons from './HeaderButtons';
import { getLocalStorageItem, setLocalStorageItem, useRel } from '../system';

// =====================================================================================
// on windows power shell say:
//      $env:NODE_OPTIONS = "--openssl-legacy-provider"
// TODO: does stored token allow access to course where the target feedback of userCode is?
// =====================================================================================
async function isTokenValid(storedToken,userCode) {
  try {
    // SLOW
    const response = await fetch(`${FEEDBACKENDPOINT_IS_ENROLLED}?userCode=${userCode}&moodleUserToken=${storedToken}`);
    const data = await response.json();

    if (data.status === 'success') {
        return data.enrolled;
    } else {
        console.error('Error fetching data:', data.message);
        return false;
    }
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
}

function MainMenu() {

  const rootDiv = document.getElementById('root');
  const loaderDiv = document.getElementById('my-body-loading-indicator-div');

  const rel = useRel();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToGo, setIsReadyToGo] = useState(false);
  const [answerData, setAnswerData] = useState(false);
  const [message, setMessage] = useState([]);
  const {userCode, moodleUserToken:moodleUserTokenFromUrl=''} = useParams();
  const [moodleUserToken, setMoodleUserToken] = useState(moodleUserTokenFromUrl);
  const [isSameDevice, setIsSameDevice] = useState(false);
  const [courseId, setCourseId] = useState(-1);

  // create new moodle user only if there is no moodleUserToken in Url 
  useEffect( () => {
    const checkToken = async () => {
      const storedToken = getLocalStorageItem('moodleUserToken');
      if (storedToken) {
        const enrolled = await isTokenValid(storedToken,userCode);
        if (enrolled == true) {
          setMoodleUserToken(storedToken);
          setIsSameDevice(true); // we found a valid old token, we are on the same machine
        } else {
          setIsSameDevice(false);  // we are on a new machine
          // Token is not valid, create a new one
          moodleUserInit(userCode, (newToken) => {
            setMoodleUserToken(newToken);
            setLocalStorageItem('moodleUserToken', newToken);
          });
        }
      } else {
        setIsSameDevice(false);  // we are on a new machine
        // No token in local storage, create a new one
        moodleUserInit(userCode, (newToken) => {
          setMoodleUserToken(newToken);
          setLocalStorageItem('moodleUserToken', newToken);
        });
      }
    };
  
    if (!moodleUserToken) {
      checkToken();
    } 

   }, [moodleUserToken, moodleUserTokenFromUrl]);

  useEffect( () => {
      if(!moodleUserToken) return;
      console.log("moodleUserToken: ", moodleUserToken)
      // SLOW
      fetchMoodleAnswerCodes(userCode, setAnswerData, (isLoading)=>{setIsLoading(isLoading);setIsReadyToGo(true);} );
  }, [moodleUserToken]);

  useEffect( () => {
    if(!moodleUserToken) return;
    if(!answerData) return;
    setCourseId(answerData.courseid)

  }, [moodleUserToken, answerData]);

  const handleConfirmAction = async (answerCode, fbid) => {
    // Define what happens when the user confirms
    const launch = await launchFeedback(fbid, moodleUserToken);
    navigate('/fb/'+userCode+'/'+answerCode+'/'+moodleUserToken+'/'+fbid+'/'+courseId);
  };
  
  if(courseId<0) return null;
  if(isLoading) return <FullScreenLoader open={isLoading}/>;
  if(!isReadyToGo) return null;
  if(!answerData) return null;
  
  if (rootDiv) {
    rootDiv.style.display = 'flex';
    loaderDiv.style.display = 'none';
  }
  
  const buttonColors=[];
  // if($r->completedid == 0)  $answer_status[]='not started';
  // if($r->completedid < 0)  $answer_status[]='started';
  // if($r->completedid > 0)  $answer_status[]='ready';
  let countAllFeedbacks = 0;
  let countCompletedFeedbacks = 0;
  const statFi=[]
  const hasBeenAnswered=[];
  if (Array.isArray(answerData.status)) {
    answerData.status.forEach((stat) => {
        countAllFeedbacks++;
        // Uncomment and use these lines if needed
        if (stat === "ready") { 
            buttonColors.push("success"); 
            statFi.push(" (valmis)");
            countCompletedFeedbacks++;
            hasBeenAnswered.push(true)
        }
        if ( (stat === "started") && isSameDevice ) { 
            buttonColors.push("primary"); 
            statFi.push(" (odottaa)");
            hasBeenAnswered.push(false);
        }
        if ( (stat === "not started") || (stat === "started" && !isSameDevice) ) { 
            buttonColors.push("primary"); 
            statFi.push(" (odottaa)");  
            hasBeenAnswered.push(false);          
        }
    });
  } 

  return (
    <Box
        sx={{
          overflowY: 'auto', // Enable vertical scroll
          display: 'flex', // Use flex layout
          flexDirection: 'column', // Column direction for the children
          justifyContent: 'flex-start', // Align children to the start of the container
        }}
      >
      <HeaderButtons userCode={userCode} />
      <Stack
          direction="column"
          spacing={1}
          alignItems="center" // Horizontally center the items
          justifyContent="center"
          sx={{
            marginTop: '10vh', // Top margin set to one third of the viewport height
            marginBottom: "5rem", // Top margin set to one third of the viewport height
            width: '100%', // Full width
          }}>
        
        <QuestionTypography 
            text={"Ole hyvä,\nanna palautetta:"}
            sx={{
              textAlign:"center",
              p:0,
            }}
          />
        <Typography
            variant="subtitle2"
            gutterBottom
            align="center"
            sx={{
                pb: rel(1),
            }}
          >
          {"valmiina "+countCompletedFeedbacks+" / "+countAllFeedbacks}
        </Typography>

        {answerData.answerCodes.map((answerCode, index) =>
                    <CompleteFeedbackButton
                          key={index}
                          buttonText={answerData.names[index]+statFi[index]}
                          hasBeenAnswered={hasBeenAnswered[index]}
                          dialogTitle="Ole hyvä ja huomioi tämä:"
                          dialogMessage="Olet jo antanut tämän palautteen. Jos muokkaat palautetta, muista klikata 'Tallenna' viimeisellä sivulla, muutoin tiedot eivät päivity."
                          onConfirm={() => handleConfirmAction(answerCode, answerData.fbids[index])}
                          buttonProps={{
                            variant:"contained",
                            color:buttonColors[index],
                            sx:{
                              width: '80%',
                              maxWidth: 400,
                              fontWeight:700,
                              m:0,
                              p: rel(1),
                              pt: rel(0.3),
                              pb: rel(0.3),
                            }
                      }}
                   />

                  )
         }

      </Stack>
    </Box>
  );
}
  
export default MainMenu;