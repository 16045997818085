import { FEEDBACKENDPOINT_ANSWER_CODES } from '../config_firebase'

const  fetchMoodleAnswerCodes =  (userCode, setAnswerData, setIsFetching) => {
    // Define your parameters, and query string and URL
    const params = {
        userCode
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${FEEDBACKENDPOINT_ANSWER_CODES}?${queryString}`;
    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setAnswerData(data.data);
            setIsFetching(false); // Stops main menu spinner
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });

    }

export default fetchMoodleAnswerCodes
