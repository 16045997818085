import React from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRel } from '../system';
import QuestionTypography from './items/QuestionTypography';



const ThankYou = () => {
  const rel = useRel();
  const navigate = useNavigate();
  const {userCode} = useParams();
  return (

    <Box
        sx={{
          width: '100vw', // Full viewport height
          maxWidth: "1200px",
          height: '100vh', // Full viewport height
          overflowY: 'auto', // Enable vertical scroll
          display: 'flex', // Use flex layout
          flexDirection: 'column', // Column direction for the children
          justifyContent: 'flex-start', // Align children to the start of the container
        }}
      >

        <Stack
              direction="column"
              spacing={10}
              alignItems="center" // Horizontally center the items
              justifyContent="center"
              sx={{
                marginTop: '20vh', // Top margin set to one third of the viewport height
                width: '100%', // Full width
                fontWeight: 700,
              }}              
          >

        <QuestionTypography 
                    text={"Kiitos palautteestasi!"}
                    sx={{
                      textAlign:"center",
                      p:0,
                    }}
                  />

          <Button
              size="large"

              variant="contained"
              color="success"
              sx={{
                width: '80%',
                maxWidth: 400,
                fontWeight:700,
                m:0,
                p: rel(1),
                pt: rel(0.3),
                pb: rel(0.3),
}}
              onClick={()=>navigate('/mm/'+userCode)}
            >
              <span>Jatka etusivulle</span>
            </Button>
      </Stack>

    </Box>
  );
};

export default ThankYou;