import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import renderItem from '../lib/renderItem';
import { useRel } from '../system';

function ShowItems({items, itemValues, setItemValue,  oldValues, onValidityChange, onItemsChange}) {
  const rel = useRel();
  return (
    <Box>
      {items ? (
        <Container sx={{ backgroundColor:"none"}} style={{margin:"0px", padding:"0px", paddingTop:rel(2.5)}}>
          {items.map((item, index) => (
              <Box 
                key={index}
                sx={{
                  mb: index === items.length - 1 ? 1 : 3, // Remove marginBottom for the last item
                  backgroundColor: "#EEEEEE",
                  p: 0,
                  m: 0,
                  mt: rel(1),
                  borderRadius: rel(0.75),
                  }}
                >
                  {renderItem({item, setItemValue, oldValues, onValidityChange, onItemsChange})}
              </Box>
          ))}
        </Container>
      ) : (
        <p>Questions are not ready</p>
      )}
    </Box>
)}

export default ShowItems;