import { getFunctions, httpsCallable } from 'firebase/functions';
import { fb } from '../config_firebase'; 

const functionsService      = getFunctions(fb, 'europe-north1');
const createMoodleUser      = httpsCallable(functionsService, 'createMoodleUser');
const getMoodleToken        = httpsCallable(functionsService, 'getMoodleToken');
const enrollUserToCourse    = httpsCallable(functionsService, 'enrollUserToCourse');

// A reusable async function to call a sequence of endpoints
// A reusable async function to call a sequence of endpoints
const moodleUserInit = (userCode, setMoodleUserToken) => {

    let user = '';
    let courseId = -1;
    createMoodleUser({userCode}) // usercode needed, because we need to know the courseId to enroll user to a new course...
        .then(response1 => {
            user = response1.data.data[0];
            courseId = response1.data.courseid;
            return getMoodleToken({ username: user.username });
        })
        .then(response2 => {
            const token = response2.data.token;
            setMoodleUserToken(token);
            
            // store moodle token for later use
            localStorage.setItem('moodleUserToken', token);
            
            const userId = user.id;
            return enrollUserToCourse({ courseId, userId });
        })
        .then(response3 => {
            // Handle the final response if needed
        })
        .catch(error => {
            console.error("An error occurred during Moodle user creation:", error);
            // Handle the error
            return {
                errorcode : "backend_internal_error",
                exception : "moodle_exception",
                message   : "backend internal error"
            };
        });
};


export default moodleUserInit

