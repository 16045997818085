import { FEEDBACKENDPOINT_SET_COMPLETED } from '../config_firebase'

function setCompletedTmp({moodleUserToken, feedbackid, answerCode}) {
      
    // Define your parameters, and query string and URL
    const params = {moodleUserToken, feedbackid, answerCode};
    
    const queryString = new URLSearchParams(params).toString();
    const url = `${FEEDBACKENDPOINT_SET_COMPLETED}?${queryString}`;
    fetch(url)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .catch(error => {
          console.error('Fetch error:', error);
      });

}

export default setCompletedTmp; 