import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { useFontSize } from './FontSizeProvider';


// Lazy load the NothingToSeeHere component
//const NothingToSeeHereLazy = lazy(() => import('./components/NothingToSeeHere'));
//const MainMenuLazy         = lazy(() => import('./components/MainMenu')); 
//const ShowFeedback         = lazy(() => import('./components/MainMenu')); 

// https://stackoverflow.com/questions/72077974/what-is-the-purpose-of-the-fontsize-theme-setting-when-all-typography-variants-a

const ThemeComponent = ({ children }) => {
  const { fontSize } = useFontSize();

  let AppTheme = React.useMemo(() => {
    return createTheme({

      components: {
            // Style every MUI Container
            MuiContainer: {
              styleOverrides: {
                root: { // Use 'root' to target the base style of the component

                },
                },
              },
            },
    
        typography: {
            htmlFontSize: 16,
            fontSize: fontSize, 
            // The default font family
            fontFamily: '"Robotoregular", "Helvetica", "Arial", sans-serif',
    
            button: {
              fontWeight: 700, // Font weight for <h1>
              lineHeight: 1.3,
            },
            // You can also define font properties for specific text variants
            h1: {
                fontWeight: 500, // Font weight for <h1>
            },
            h2: {
                fontWeight: 400,
            },

            // Feedback button
            h4: { 
              textAlign:"left", 
              fontWeight: 700,
              paddingLeft: "1rem",
              paddingTop:"0.5rem",
              paddingBottom:"0.5rem",
              // '@media (max-width:600px)': {fontSize: '1rem' },
          },
          

            // Feedback target name
            h5: { 
                textAlign:"left", 
                fontWeight: 700,
                paddingLeft: "1rem",
                paddingTop:"0.5rem",
                paddingBottom:"0.5rem",
                // '@media (max-width:600px)': {fontSize: '1rem' },
            },
            
            // question text QuestionTypography
            h6: { 
                lineHeight: '1.2', 
                textAlign:"left", 
                //'@media (max-width:600px)': {fontSize: "1rem" },
                fontWeight: 700,
                paddingBottom:"0.85rem",
                // '@media (max-width:600px)': {paddingBottom: },
            },
    
            // scale ends
            subtitle1:{
                lineHeight: '1.2', 
                textAlign: 'left',
                fontSize: '1rem', // default font size for larger screens
                //'@media (max-width:600px)': {fontSize: '0.85rem' }
            },
    

            // scale ends, {"valmiina "+countCompletedFeedbacks+" / "+countAllFeedbacks}
            subtitle2:{
              lineHeight: '1.2', 
              textAlign: 'left',
              //'@media (max-width:600px)': {fontSize: '0.85rem' }
          },

            // Global settings
            fontWeightLight: 300, // Light weight
            fontWeightRegular: 400, // Regular weight
            fontWeightMedium: 500, // Medium weight
            fontWeightBold: 700, // Bold weight
        },
    });
  }, [fontSize]);

  return (
    <ThemeProvider theme={AppTheme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;

