import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';

function FooterInfo({ message="" }) { // message is received as a prop here
  if (message == "" ){
    return null
  }
  return (
    <Box sx={{
      position: 'fixed',
      left:30,
      right: 30,
      bottom: 30,
      p: 1,
      }} maxWidth="md" style={{ marginLeft: 'auto', marginRight: 'auto' }}>

    <Container maxWidth="md" sx={{ p: 0 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ p: 0 }}>
            {/* Use the message prop in the Alert component */}
            <Alert
              icon={<WarningIcon fontSize="inherit" />}
              severity="warning"
              sx={{ justifyContent: 'center', width: '100%' }}
            >
              {message} {/* This is where the passed message is displayed */}
            </Alert>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default FooterInfo;