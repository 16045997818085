import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Alert, Checkbox, Grid, Slider, Typography } from '@mui/material';
import QuestionTypography from './QuestionTypography';
import { useTheme } from '@emotion/react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import UncheckedIcon from './UncheckedIcon';
import { useRel } from '../../system';

// If you have additional MUI components you want to use, import them here

// This is the template for your TypeAComponent
// It expects to receive an "item" prop that it will use to display content

function integerDivision(x, y) {
  //return x / y < 0 ? Math.ceil(x / y) : Math.floor(x / y);
    return Math.floor(x / y);
}

function ItemSlider({   item,                // current slider component to show
                        setItemValue,        // set the itemValue in state array stored in <CompleteFeedback>
                        oldValues,           // old values stored in Moodle db
                        onValidityChange,    // array holding id => validity info
                        onItemsChange,       // array of function calls [id]: helperFunction(), helperFunction shows helper messages upon next page button click, say,
                        // leftLabel=<span>{"täysin"}<br/>{"eri"}<br/>{"mieltä"}</span>,
                        // rightLabel=<span>{"täysin"}<br/>{"samaa"}<br/>{"mieltä"}</span>,
                        leftLabel=<span>{"ei koskaan"}</span>,
                        rightLabel=<span>{"aina"}</span>,

                    }) {

  const [value, setValue] = useState(0);            // slider component value goes -1000..+1000
  const [showHelp, setShowHelp] = useState(false);  // state variable controlling if a help message should be shown
  const [checked, setChecked] = useState(false);    // state varibale for "I do not know" checkbox
  const theme = useTheme();

  const rel = useRel();

  // customized CheckBox
  const uncheckedIcon = UncheckedIcon;
  const checkedIcon = <CheckBoxIcon />;

  // basic validation of the item value
  // if checked, we have "I do not know" situation, and everything goes
  const validateValue = (currentValue, checked) => {
    if(checked) return true
    if(currentValue===undefined||currentValue===null) return false
    return true;
  };

  // this will update stuff when slider is moved
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);                                       // Set the local component item value  
    setItemValue(item.id, newValue*10);                       // Set the item value in the <CompleteFeedback> component's state
    const currentIsValid = validateValue(newValue, checked);  // Check validity of current question value
    onValidityChange(item.id, currentIsValid);                // Call the function that updates the itemsValidity array in <CompleteFeedback>
    // this will store the magical helper message to an array of functions in <CompleteFeedback>
    onItemsChange(item.id, ()=>{const v=newValue; const c=checked; return (() => { setShowHelp(!validateValue(v,c))})() } );
    setShowHelp(!validateValue(newValue, checked))               // update the state of this component, if we should show help message
  };

  // this will handle the state change of the "I do not know" checkbox
  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);                         // update state variable
    const currentIsValid = validateValue(value,checked);      // check validity after state change
    onValidityChange(item.id, currentIsValid);                // update state in  <CompleteFeedback>
    // this will store the magical helper message to an array of functions in <CompleteFeedback>
    onItemsChange(item.id, ()=>{const v=value; const c=event.target.checked; return (() => { setShowHelp(!validateValue(v,c))})() } );
  };

  // ====================================
  // this will handles checkbox state change
  // ====================================
  useEffect(()=>{
      if(checked){
        setItemValue(item.id, value*10+5);
      } else {
        setItemValue(item.id, value*10);
      }
      const currentIsValid = validateValue(value, checked);
      onValidityChange(item.id, currentIsValid);
      setShowHelp(!validateValue(value, checked))
  },[checked]);

  // ====================================
  // IMPORTANT
  // update whole state if item changes
  // this will handle also default / filled in values
  // ====================================
  useEffect(() => {
      const foundItem = oldValues.find(element => element.item === item.id);          // find values from oldValues fetched from Moodle db
      const oldValue = foundItem ? integerDivision(foundItem.value,10) : undefined;   // scale down by 10 or set undefined (first run)
      setValue(oldValue||null);                                                       // set default value for this Slider component, null required
      if(oldValue!==undefined) {
        setItemValue(item.id, oldValue*10);                                           // set oldValue to itemValue in <CompleteFeedback>, if oldValue is not undefined (not the first run)
      }
      const currentIsValid = validateValue(oldValue, checked);                        // check item validity
      onValidityChange(item.id, currentIsValid);                                      // update validity info in <CompleteFeedback>
      
      // hack for "I do not know" choice
      // foundItem.value goes from -1000 .. + 1000, slider values goes from -100..+100
      // values not even multiples of ten idicate that "I do not know" choice has been made
      if(foundItem){
        setChecked(foundItem.value % 10 ? true: false); 
      }
      // this will store the magical helper message function to an array of functions in <CompleteFeedback>
      onItemsChange(item.id, ()=>{const v=oldValue; const c=checked; return (() => { setShowHelp(!validateValue(v,c))})() });
  }, [item]); 

  // ====================================
  return (
    <Grid container spacing={0} sx={{p:1, m:0}}  >
      {/* Main content */}
      <Grid item xs={12} md={10} >
          <QuestionTypography text={item.name} showHelp={showHelp} helpText={"Ole hyvä ja tee ensin valintasi liukuasteikolta:"} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" disabled={checked} sx={{color: checked?"#AAA":"black", fontSize:rel(1)}} > {leftLabel} </Typography>
            <Typography gutterBottom align="center" sx={{ alignSelf: 'flex-end', color: checked?"#AAA":"black", fontSize:rel(1)}} > { /* Align this item to the bottom of the flex container */ }
                {parseInt(value/2+50)}
            </Typography>
            <Typography variant="subtitle1" sx={{color: checked?"#AAA":"black", fontSize:rel(1)}}> {rightLabel} </Typography>
          </Box>
          <Slider
            disabled={checked}
            value={Number(value)}
            onChange={handleSliderChange}
            min={-100}
            max={100}
          />
      </Grid>

      {/* Checkbox Grid Item */}
      <Grid 
          item 
          xs={12} 
          md={2} 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'row', md: 'column' },
            alignItems: 'center',  // Vertically center align items on all screen sizes
            justifyContent: { xs: 'flex-start', md: 'center' }, // Align items to the left on xs screens
            mb: { xs: 0, md: 0 },

          }}
        >
        {/* Checkbox text */}
        <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            sx={{
              [theme.breakpoints.up('md')]: {
                whiteSpace: 'pre-wrap'
              },
              mt: { xs: 1, md: 0 },
              pt: { xs: 0, md: 0},
              fontSize: rel(1),
            }}
          >
          {"en\nosaa\nsanoa"}
        </Typography>

        {/* Checkbox itself */}
        <Checkbox
            checked={checked}
            onChange={handleCheckBoxChange}
            checkedIcon={checkedIcon} // Default icon for checked state
            sx={{
              mt: { xs: 0, md: 0 },          
              pb: { xs: 1, md: 0 }, 
              fontSize: rel(1.2),
            }}
          />

      </Grid>
  </Grid>

  );
}

export default ItemSlider;
