import React, { useState, useCallback } from 'react';
import { ENDPOINT } from '../config_firebase'
import fetchFeedbackResponses from './fetchFeedbackResponses';

function useGetFeedbackQuestionsForPage({userCode, answerCode, token, feedbackId, setIsLoading, setItemsFullList}) {
    const [items, setItems] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [pageCount, setPageCount] = useState(-1);

    const [responses, setResponses] = useState([]);
    const [errors, setErrors] = useState(null);


    // mod_feedback_get_page_items returns:
    // hasnextpage : true
    // hasprevpage : false
    // items       : Array(1) 0  : {id: 12, feedback: 4, template: 0, name: 'Anna eka luku (FB-1)', label: '', …}
    // warnings    : Array(1)
    // length  :  1

    const getFeedbackQuestionsForPage = useCallback(async (pageNumber) => {

      //console.log(">>>>>>>>> Working in getFeedbackQuestionsForPage... ");
      
      // sanity check, return immediately if something essential is missing
      if (!userCode||!answerCode||!token||!feedbackId) {
        setItems([])
        setHasNextPage(false)
        setHasPrevPage(false)
        setErrors([])
        setResponses([])
        setPageCount(-1)
        return;
      }

      // =========================================================================
      // read questons for this page from db
      // =========================================================================

      pageNumber=pageNumber?pageNumber:0
      const params = new URLSearchParams({
        wstoken: token,
        wsfunction: 'mod_feedback_get_page_items',
        moodlewsrestformat: 'json',
        feedbackid: feedbackId.toString(),
        page: pageNumber.toString(),
      });

      try {
        // fetch data from /webservice/rest/server.php`
        const response = await fetch(`${ENDPOINT}?${params}`, {
          method: 'POST',
        });
  
        if (!response.ok) {
          console.error("response not OK")
        }

        const data = await response.json();
        
        setItems(data.items); // Set the items in state
        setHasNextPage(data.hasnextpage)
        setHasPrevPage(data.hasprevpage)
        setErrors(data.warnings)
  
      } catch (error) {
        console.error('Error fetching feedback questions:', error);
        return;
      }

    // =========================================================================
    // read possible OLD answers for this session from db
    // =========================================================================
      try {
        const {pageCount, mergedResoponses, itemsFullList} = await fetchFeedbackResponses(token, feedbackId);
        setPageCount(pageCount);
        setItemsFullList(itemsFullList.items); // this itemsFullList is differentfrom the stateVar
        setResponses(mergedResoponses); // Set the responses in state

      } catch (error) {

        // oooooooooooooooooooooooooooooooooooooooooooooooooooooo
         console.error('Fetch error:', error);
        // oooooooooooooooooooooooooooooooooooooooooooooooooooooo
        return [items, hasNextPage, hasPrevPage, responses, errors, getFeedbackQuestionsForPage, pageCount];

      }
      
      setIsLoading(false) // THE MAIN POSITION TO REMOVE LOADING INDICATOR

    }, [userCode, answerCode, token, feedbackId, errors, hasNextPage, hasPrevPage, items, pageCount, responses, setIsLoading, setItemsFullList]); // END OF getFeedbackQuestionsForPage, Only re-create the function when `token` changes


    // =========================================================================
    // =========================================================================
    return [items, hasNextPage, hasPrevPage, responses, errors, getFeedbackQuestionsForPage, pageCount];
  }

  export default useGetFeedbackQuestionsForPage;