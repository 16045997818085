
import { ENDPOINT } from '../config_firebase'
                                    
async function processFeedbackPage(feedbackId, currentPage, responses, token, goprevious, answerCode) {
    const functionName = "mod_feedback_process_page";
    const params = new URLSearchParams({
      wstoken: token, 
      wsfunction: functionName,
      moodlewsrestformat: "json",
      feedbackid: feedbackId,
      page: currentPage,
      goprevious:goprevious,
      ...responses
    });
  
    try {
      const response = await fetch(ENDPOINT, { 
        method: 'POST',
        body: params
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const jsonResponse = await response.json();
      // result.completed == true then were done, otherwise there something to do still

      return jsonResponse;
    } catch (error) {
      console.error('Error during the API call:', error);
      return null; // Or handle the error as per your needs
    }
}
export default processFeedbackPage