import React, { createContext, useContext, useState, useEffect } from 'react';
import { getLocalStorageItem, isMobile, setLocalStorageItem } from '../system';
import { FONT_SIZES } from '../config_firebase';

const FontSizeContext = createContext();


export const FontSizeProvider = ({ children }) => {
  // Initialize font size from localStorage or default to 20
  const defaultSize = isMobile() ? FONT_SIZES[0]:FONT_SIZES[1];
  const [fontSize, setFontSize] = useState( Number.isInteger(parseInt(getLocalStorageItem('fontSize', defaultSize)))?parseInt(getLocalStorageItem('fontSize', defaultSize)): defaultSize);
     
  

  // Update localStorage whenever fontSize changes
  useEffect(() => {
    setLocalStorageItem('fontSize', fontSize);
  }, [fontSize]);

  return (
    <FontSizeContext.Provider value={{ fontSize, setFontSize }}>
      {children}
    </FontSizeContext.Provider>
  );
};

export const useFontSize = () => useContext(FontSizeContext);