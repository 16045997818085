import { ENDPOINT } from '../config_firebase'

function arrayMerge(array1, array2) {
    const mergedMap = new Map();

    // Populate the map with objects from the first array
    array1.forEach(obj => mergedMap.set(obj.item, obj));

    // Override with objects from the second array
    array2.forEach(obj => mergedMap.set(obj.item, obj));

    // Convert the map values back to an array
    return Array.from(mergedMap.values());
}


async function fetchFeedbackResponses(token, feedbackId) {

    // Prepare parameters for the finished responses request
    const params1 = new URLSearchParams({
        wstoken: token,
        wsfunction: 'mod_feedback_get_finished_responses',
        moodlewsrestformat: 'json',
        feedbackid: feedbackId.toString(),
    });

    // Prepare parameters for the unfinished responses request
    const params2 = new URLSearchParams({
        wstoken: token,
        wsfunction: 'mod_feedback_get_unfinished_responses',
        moodlewsrestformat: 'json',
        feedbackid: feedbackId.toString(),
    });

    // Prepare parameters for the unfinished responses request
    const params3 = new URLSearchParams({
        wstoken: token,
        wsfunction: 'mod_feedback_get_items',
        moodlewsrestformat: 'json',
        feedbackid: feedbackId.toString(),
    });


    // Create the fetch requests
    const request1 = fetch(`${ENDPOINT}?${params1}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    const request2 = fetch(`${ENDPOINT}?${params2}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    const request3 = fetch(`${ENDPOINT}?${params3}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });


    try {
        // Execute both requests in parallel
        const [response1, response2, response3] = await Promise.all([request1, request2, request3]);

        // Process the responses, count pages
        const finishedResponses = await response1.json();
        const unfinishedResponses = await response2.json();
        const itemsFullList = await response3.json();
        const mergedResoponses = arrayMerge(finishedResponses.responses, unfinishedResponses.responses);
        const pageCount = itemsFullList.items.filter(item => item.typ === 'pagebreak').length + 1;

        console.log("itemsFullList: ", itemsFullList)

        return {pageCount, mergedResoponses, itemsFullList};

    } catch (error) {
        console.error('Error fetching feedback responses:', error);
        throw new Error('Network response was not ok');
    }
}

export default fetchFeedbackResponses
