import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const CompleteFeedbackButton = ({ buttonText, dialogTitle, dialogMessage, onConfirm, hasBeenAnswered, buttonProps }) => {

  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
        // double checking only relevant if user has already answered
        if(!hasBeenAnswered){
          handleConfirm();  
          return;
        }
        setOpen(true);
      }
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleOpen}>
        <Typography variant="button" >{buttonText}</Typography> 
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{textAlign: "center"}}>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{textAlign: "center"}}>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Jatka
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompleteFeedbackButton;
