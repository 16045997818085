import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Alert, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useRel } from '../../system';
// If you have additional MUI components you want to use, import them here

const QuestionTypography = ({ text, showHelp, helpText, sx}) => {
  // You can access all properties of "item" and use them in your component
  // For example, if "item" has a "name" property, you can display it like so

  const theme = useTheme();
  const rel = useRel();

  return (
    <Typography   variant="h6" sx={{ fontSize:rel(1.2), ...sx}} >
        {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ))}
    {showHelp &&
          (    
          
            <Alert
                sx={{
                //'@media (max-width:600px)': {fontSize: '0.8rem' },
                padding: rel(0.1)+" "+rel(0.1), // You can adjust the padding here as needed
                '.MuiAlert-icon': {
                    padding: rel(0.1)+" "+rel(0), // Reduces the space around the icon
                },
                '.MuiAlert-message': {
                    padding: rel(0.1)+" "+rel(0), // Reduces the space around the message
                },
                }}
                severity={"error"}>
                {helpText}
            </Alert>
        )
    }
</Typography>
);
};

// Make sure to export your component so it can be used in other parts of your app
export default QuestionTypography;
