import { ENDPOINT } from '../config_firebase'

const  launchFeedback =  async (feedbackId, token) => {
    // Construct the parameters to send to Moodle
    const params = new URLSearchParams({
      wstoken: token,
      wsfunction: 'mod_feedback_launch_feedback',
      moodlewsrestformat: 'json',
      feedbackid: feedbackId, // Replace with the actual feedback ID
    });
  
    // Perform the API request
    // Return the fetch Promise chain
    const response = await fetch(`${ENDPOINT}?${params.toString()}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        })

    if (!response.ok) {
      throw new Error('Network response was not ok: launchFeedback');
    }
    const data = await response.json();
    return data

  }

export default launchFeedback
