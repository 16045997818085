// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAoOgj30pWl15KFWx8GoJmByO3_omNJmLI",
  authDomain: "lomake-70550.firebaseapp.com",
  databaseURL: "https://lomake-70550-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lomake-70550",
  storageBucket: "lomake-70550.appspot.com",
  messagingSenderId: "119131723709",
  appId: "1:119131723709:web:2bd836d6b50d46eb8164d4",
  measurementId: "G-KQ5B6R9563"
};


// this is how to reset Moodle admin pwd
// sudo -u www-data /usr/bin/php /var/www/moodle_dev/moodle/admin/cli/reset_password.php
// admin / Tikankolo61!

// Initialize Firebase
const fb = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(fb);

// Initialize Cloud Functions
// const functions = firebase.app().functions('europe-central2');
const functions = getFunctions(fb);

const MOODLE_URL                                = "https://lomake.ahaavision.com";
const ENDPOINT                                  = `${MOODLE_URL}/webservice/rest/server.php`;
const FEEDBACKENDPOINT_ANSWER_CODES             = `${MOODLE_URL}/mod/feedback/ahaa/ws-answer-codes.php`;
const FEEDBACKENDPOINT_SET_COMPLETEDTMP         = `${MOODLE_URL}/mod/feedback/ahaa/ws-set-completedtmp.php`;
const FEEDBACKENDPOINT_SET_COMPLETED            = `${MOODLE_URL}/mod/feedback/ahaa/ws-set-completed.php`;
const FEEDBACKENDPOINT_GET_COURSEID             = `${MOODLE_URL}/mod/feedback/ahaa/ws-get-courseid.php`;
const FEEDBACKENDPOINT_GET_MANAGER_DETAILS      = `${MOODLE_URL}/mod/feedback/ahaa/ws-get-manager-details.php`;
const FEEDBACKENDPOINT_GET_INFO                 = `${MOODLE_URL}/mod/feedback/ahaa/ws-get-info.php`;
const FEEDBACKENDPOINT_GET_STATISTICS           = `${MOODLE_URL}/mod/feedback/ahaa/ws-get-statistics.php`;
const FEEDBACKENDPOINT_IS_ENROLLED              = `${MOODLE_URL}/mod/feedback/ahaa/ws-is-enrolled.php`;


const FONT_SIZES                                = [12, 16, 20];

export { 
    fb, 
    analytics, 
    functions, 
    ENDPOINT, 
    FEEDBACKENDPOINT_ANSWER_CODES, 
    FEEDBACKENDPOINT_SET_COMPLETEDTMP,
    FEEDBACKENDPOINT_SET_COMPLETED,
    FEEDBACKENDPOINT_GET_COURSEID,
    FEEDBACKENDPOINT_GET_MANAGER_DETAILS,
    FEEDBACKENDPOINT_GET_INFO,
    FEEDBACKENDPOINT_GET_STATISTICS,
    FEEDBACKENDPOINT_IS_ENROLLED,
    FONT_SIZES
  };