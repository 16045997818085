import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FullScreenLoader = ({ open }) => {

  const styles = {
    // Apply a transition to the opacity for a smooth change over 1 second
    transition: 'opacity 1s ease',
    color: '#fff', 
    zIndex: (theme) => theme.zIndex.drawer + 1 ,
  };
  return (
    <Backdrop
      open={open}
      sx={styles} // Apply the custom styles
      transitionDuration={{
        appear: 0,
        enter: 1000, // 1000ms for enter transition
        exit: 500,  // 500ms for exit transition, for example
      }}      
  
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullScreenLoader;
