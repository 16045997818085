import React from 'react';
import ItemEndScreen from "../components/items/ItemEndScreen";
import ItemSlider from "../components/items/ItemSlider";
import ItemTextArea from "../components/items/ItemTextArea";
import ItemLogArea from "../components/items/ItemLogArea";

const renderItem = ({ item, setItemValue, oldValues, onValidityChange, onItemsChange }) => {
  // Determine which component to use based on the item type
  switch (item.typ) {
    case 'numeric':
    case 'aslider':
      return (
        <ItemSlider
          key={item.id}
          item={item}
          setItemValue={setItemValue}
          oldValues={oldValues}
          onValidityChange={onValidityChange}
          onItemsChange={onItemsChange}
        />
      );

    case 'aendscreen':
      return (
        <ItemEndScreen
          key={item.id}
          item={item}
          setItemValue={setItemValue}
          oldValues={oldValues}
          onValidityChange={onValidityChange}
          onItemsChange={onItemsChange}
        />
      );

    case 'textarea':
      return (
        <ItemTextArea
          key={item.id}
          item={item}
          setItemValue={setItemValue}
          oldValues={oldValues}
          onValidityChange={onValidityChange}
          onItemsChange={onItemsChange}
        />
      );

    case 'alogvars':
    case 'alogsystem':
      return (
        <ItemLogArea
          key={item.id}
          item={item}
          setItemValue={setItemValue}
          oldValues={oldValues}
          onValidityChange={onValidityChange}
          onItemsChange={onItemsChange}
        />
      );

    default:
      return <p key={item.id}>Unknown type</p>;
  }
};

export default renderItem;
