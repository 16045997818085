import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FEEDBACKENDPOINT_GET_STATISTICS } from '../config_firebase';
import { useEffect, useState } from 'react';
import { useRel } from '../system';
import { Button, Container } from '@mui/material';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

// import 'chartjs-adapter-date-fns';
// import differenceInSeconds from 'date-fns/differenceInSeconds';
// is replaced by that below:

/**
 * Calculates the difference in seconds between two dates.
 * 
 * @param {Date | string | number} date - The later date.
 * @param {Date | string | number} firstTimestamp - The earlier date.
 * @returns {number} - The difference in seconds.
 */
function differenceInSeconds(date, firstTimestamp) {
    // Ensure the inputs are Date objects
    const date1 = new Date(date);
    const date2 = new Date(firstTimestamp);
  
    // Get the difference in milliseconds
    const diffInMilliseconds = date1.getTime() - date2.getTime();
  
    // Convert the difference to seconds
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  
    return diffInSeconds;
  }


function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}.${month}.${year} klo ${hours}:${minutes}`;
  }


function Statistics({ userCode, handleClose }) {

    const rootDiv = document.getElementById('root');
    const loaderDiv = document.getElementById('my-body-loading-indicator-div');
  
    const {folderId} = useParams();
    const [data, setData]=useState(null);

    useEffect(()=>{
        if(!folderId) return;
        const params = {
          folderId,
        };

        const queryString = new URLSearchParams(params).toString();
        const url = `${FEEDBACKENDPOINT_GET_STATISTICS}?${queryString}`;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
              setData(data.data); 
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });

    },[userCode]);
    
  if(!data) return null;

  
  if (rootDiv) {
    rootDiv.style.display = 'flex';
    loaderDiv.style.display = 'none';
  }


  return (
    <TimeSeriesPlot timestamps={data.timestamps} maxAnswerCount={data.maxAnswerCount} maxDays={14}/>
    );
}

export default Statistics;


const TimeSeriesPlot = ({ timestamps, maxAnswerCount, maxDays }) => {

 
  console.log("Raw Timestamps:", timestamps);

  let dates = timestamps.map(ts => new Date(ts * 1000));
  console.log("Converted Dates:", dates);

  dates.sort((a, b) => a - b);
  const firstTimestamp = dates[0];
  console.log("First Timestamp:", firstTimestamp);

  const transformedDates = dates.map(date => {
      const secondDifference = differenceInSeconds(date, firstTimestamp);
      return secondDifference;
  });

  console.log("Second Differences:", transformedDates);

  const maxSeconds = maxDays * 24 * 60 * 60;
  const filteredTransformedDates = transformedDates.filter(secondDifference => secondDifference <= maxSeconds);

  console.log("Filtered Transformed Dates:", filteredTransformedDates);
  const anow = new Date();
  const percentage = timestamps.length/maxAnswerCount*100;
  const chartData = {
      labels: filteredTransformedDates,
      datasets: [
          {
              label: 'Vastaustilanne '+formatDate(anow)+' : tallentuneiden vastausten määrä on nyt ' +  
                        timestamps.length+' ja vastausprosentti ' + 
                        timestamps.length+' / '+maxAnswerCount+" = "+  Number(percentage.toFixed(1))+" %",
              data: filteredTransformedDates.map((seconds, index) => ({ x: seconds, y: index + 1 })),
              borderColor: 'blue',
              backgroundColor: 'rgba(0, 0, 255, 0.1)',
          },
      ],
  };

  console.log("Chart Data:", chartData);

  const options = {
    scales: {
        x: {
            type: 'linear',
            position: 'bottom',
            title: {
                display: true,
                text: 'Päiviä aloituksesta',
            },
            ticks: {
                // Convert seconds to days for tick labels
                callback: function(value) {
                    return `Päiviä ${Math.floor(value / (24 * 60 * 60))}`;
                }
            },
            // Set the maximum value of the x-axis in seconds
            max: maxDays * 24 * 60 * 60
        },
        y: {
            title: {
                display: true,
                text: 'Vastausten määrä, N',
            },
            suggestedMin: 0,
            suggestedMax: maxAnswerCount,
        },
    },
};
  return <Line data={chartData} options={options} />;
};