import React, { useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';

function NothingToSeeHere({setIsLoading}) {
  
    const rootDiv = document.getElementById('root');
    const loaderDiv = document.getElementById('my-body-loading-indicator-div');
  
    if (rootDiv) {
        rootDiv.style.display = 'flex';
        loaderDiv.style.display = 'none';
      }
    
    return (
            <Box
              sx={{m:0, p:0}}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh" // Full viewport height
              width="100vw"  // Full viewport width
              style={{ backgroundColor: '#f0f0f0' }} // Optional background color
            >
                <Typography variant="h4" style={{textAlign:"center"}}>
                    <p>Tietoja ei ole saatavilla.</p>
                    <Link href="http://www.ahaavision.com" target="_blank" rel="noopener noreferrer">
                        <p>www.ahaavision.com</p>
                    </Link>
                </Typography>
            </Box>
          );
  }
  
  export default NothingToSeeHere;