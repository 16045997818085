import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FEEDBACKENDPOINT_GET_INFO } from '../config_firebase';
import { useEffect, useState } from 'react';
import { useRel } from '../system';
import { Button, Container } from '@mui/material';
import styled from '@emotion/styled';


const FixedContainer = styled(Container)({
    position: 'fixed',
    top: "10vh",
    left: '50%', // Center the container
    transform: 'translateX(-50%)', // Adjust for the container's own width
    zIndex: 1000,
    backgroundColor: 'white', // Adjust as needed
    p: 3, // Padding top 3 (using spacing from the theme)
    mt: 0, // Padding top 3 (using spacing from the theme)
    textAlign: "center",
    maxHeight: "80vh", // Set maximum height to 80% of the viewport height
    overflowY: 'auto', // Enable vertical scrolling
});


function InfoAccordion({ userCode, handleClose }) {

    const [expanded, setExpanded] = useState(false);
    const rel = useRel();
    const [info, setInfo] = useState('');

    useEffect(()=>{
        if(!userCode) return;
        const params = {
            userCode,
        };

        const queryString = new URLSearchParams(params).toString();
        const url = `${FEEDBACKENDPOINT_GET_INFO}?${queryString}`;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setInfo(data.data); 
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });

    },[userCode]);
    
  if(!info) return null;
  return (
    <FixedContainer>
      {info.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography sx={{fontWeight: 700}}>{item[0]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left"}}>
              {item[1]}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
     <Button onClick={handleClose} sx={{pt:3}} color="primary">
            Sulje
    </Button>
</FixedContainer>
  );
}

export default InfoAccordion;
